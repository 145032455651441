
  <template>
  <div v-if="selectedMarker != null">
    <div class="container">
      <div class="top-bar">
        <div>
          <b-img
            class="containerImg"
            v-show="isShowingImage"
            v-if="selectedMarker.container_type"
            :src="getImgUrl(this.selectedMarker.container_type.name + '.png')"
          ></b-img>
          <Icon
            v-show="!isShowingImage"
            v-if="selectedMarker.container_type"
            :data="getCurrentTheme.containerCrane.icon"
          ></Icon>

          <Icon
            v-if="selectedMarker.registered_at"
            :data="getCurrentTheme.carIcon.icon"
          ></Icon>

          <div class="containerName">
            <b v-if="selectedMarker.alias">{{ selectedMarker.alias }}</b>
            <b v-if="!selectedMarker.alias">{{ selectedMarker.qr_code + ' ' + selectedMarker.container_type.name }}</b>
            <p class="orgNavn" v-if="selectedMarker.registered_at">
              {{ selectedMarker.region.name }}
            </p>
          </div>
        </div>
        <div>
          <div class="exitCross" @click="setSelectMarker(null)">
            <b-icon-x-circle class="h2 icon" color="black"></b-icon-x-circle>
          </div>
          <div v-if="selectedMarker.container_type" class="arrowIcon" @click="routeToAssets(selectedMarker)">
            <b-icon-arrow-right-circle class="h2"></b-icon-arrow-right-circle>
          </div>
        </div>
      </div>

      <!-- CONTAINER CARD -->
      <div class="mid-bar">
        <ul
          class="informationList"
          v-for="(item, index) in createInformationObject"
          :key="index"
        >
          <li v-if="item.value" class="listItem">
            <span class="textColor">{{ item.name }}</span>
            <b>{{ item.value }}</b>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import Icon from "@/components/icons/Icons.vue";
import { mapGetters, mapActions } from "vuex";
import { getFillLevel } from "@/services/utils";

export default {
  name: "ItemInfo",
  components: {
    Icon,
  },

  data() {
    return {
      searchInput: "",
      isShowingImage: true,
      useTypeDict: [
        {
          key: "FoodWaste",
          value: "Matavfall",
        },
        { key: "Glass", value: "Glass" },
        {
          key: "Plastic",
          value: "Plast",
        },
        {
          key: "RecycledPaper",
          value: "Papp og Papir",
        },
        {
          key: "ResidualWaste",
          value: "Restavfall",
        },
      ],
    };
  },

  watch: {
    selectedMarker() {
      this.$forceUpdate();
    }
  },

  computed: {
    ...mapGetters(["getCurrentTheme", "selectedMarker", "getItemIcon"]),

    createInformationObject() {
      let item = this.selectedMarker;
      console.log(item);
      if (item.container_type != null) {
        let returnArray = [
          {
            name: "Use Type",
            value: this.getUseType(item.use_type),
          },
          {
            name: this.$t("qr"),
            value: item.qr_code,
          },
          {
            name: this.$t("accuracy"),
            value: item.location ? item.location.accuracy_radius + " m" : null,
          },
          {
            name: this.$t("type"),
            value: item.container_type ? item.container_type.name : null,
          },
          {
            name: this.$t("volume"),
            value: item.container_type ? item.container_type.volume + " m3" : null,
          },
          {
            name: this.$t("weight"),
            value: item.container_type ? item.container_type.weight : null,
          },
          {
            name: this.$t("size"),
            value:
            item.container_type ? 
              (item.container_type.length +
              "x" +
              item.container_type.width +
              "x" +
              item.container_type.height +
              " m") : null,
          },
          {
            name: this.$t("fill"),
            value: this.getPercentage(item) + " %",
          },
          {
            name: this.$t("hook"),
            value: item.container_type ? item.container_type.mount_type : null,
          },
          {
            name: this.$t("use"),
            value: item.container_type ? item.container_type.uses : null,
          },
          {
            name: this.$t("lastSeen"),
            value: this.changeTimestamp(item),
          },
        ];
        return returnArray;
      } else {
        // if (item.alias && item.license_plate) {
        if (item.unit && (item.unit.serial_number.startsWith("MUS") || item.unit.serial_number.startsWith("ANS"))) {
          let returnArray = [
            {
              name: this.$t("regNr"),
              value: item.license_plate ? item.license_plate.number : null,
            },
            {
              name: this.$t("lastSeen"),
              value: this.changeTimestamp(item),
            },
            {
              name: this.$t("seenBy"),
              value: item.location ? item.location.signal_source : null,
            },
            {
              name: this.$t("accuracy"),
              value: item.location ? item.location.accuracy_radius + " m" : null,
            },
          ];

          // if (item.driver) {
          //   let obj = {
          //     name: this.$t("driver"),
          //     value: item.driver.name,
          //   };
          //   returnArray.push(obj);
          // }
          return returnArray;
        } else {
          this.setSelectMarker(null);

          this.addError({
            title: this.$t("error.title"),
            message: this.$t("error.msg"),
            id: Math.random(10),
          });

          return null;
        }
      }
    },
  },

  methods: {
    ...mapActions(["setSelectMarker", "addError"]),
    routeToAssets(item) {
      this.$router.push({
        name: 'containerOverview',
        params: {
          containerClicked: item
        }
      })
    },
    changeTimestamp(item) {
      if (!item.unit || !item.location) return null;
      let date = new Date(item.unit.timestamp);
      if (date == "Invalid Date") date = new Date(item.location.timestamp);
      return date.toLocaleString("uk");
    },

    createVehicleObject(value) {
      for (var item of this.vehicleDict) {
        if (value == item.key) {
          return { name: item.value, value: null };
        }
      }
    },

    getUseType(value) {
      for (var item of this.useTypeDict) {
        if (value == item.key) return item.value;
      }
    },

    getImgUrl(arg) {
      let containerImage =
        "https://reenstorageaccount.blob.core.windows.net/containerimages/" +
        arg;
      return containerImage;
    },
    
    getPercentage(c) {
      return getFillLevel(c);
    },
  },
};
</script>

<style scoped>
.listItem {
  padding: 5px;
}
.icon {
  position: sticky;
  top: 00px;
}
.arrowIcon {
  position: sticky;
  top: 30px;
  cursor: pointer;
}
.container {
  padding: 25px;
}
.containerImg {
  max-width: 100%;
}
.orgNavn {
  font-size: 14px;
}

.informationList {
  margin: 0;
  padding: 0;
}

.textColor {
  color: rgb(0, 0, 0);
  text-align: left;
}

.containerName {
  padding-top: 10%;
  font-size: 19.2px;
}

ul {
  padding-left: 0;
  padding-bottom: 20px;
}

li {
  list-style-type: none;
  font-size: 12px;
  line-height: 1.5;
  border-bottom: 1px solid rgb(0, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 3px;
  text-align: right;
}

li:last-child {
  border: none;
  gap: 7%;
}

@media (min-width: 768px) {
  .exitCross {
    position: sticky;
    top: 0px;
  }
  li {
    list-style-type: none;
    font-size: 15px;
    line-height: 1.5;
    border-bottom: 1px solid rgb(0, 0, 0);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 3px;
    text-align: right;
  }
  .arrowIcon {
    position: sticky;
  }
}
</style>